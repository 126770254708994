import Plyr from 'plyr';

class Video {
    constructor() {

        const players = document.querySelectorAll('.video .player');

        if(players) {
            players.forEach((player) => {
                const modal = player.closest('.hero-video-modal');

                if(modal) {
                    //do modal stuff here
                    const modalTrigger = document.querySelector('.modal-video-trigger');
                    const newPlayer = new Plyr(player, {
                        debug: false,
                        ratio: '16:9',
                    });
                    newPlayer.pause();

                    if(modalTrigger) {
                        modalTrigger.querySelector('button').addEventListener('click', (e) => {
                            modal.classList.add('open');
                            window.setTimeout(() => {
                                newPlayer.play();
                            }, 250);
                        });
                    }

                    modal.querySelector('.filter').addEventListener('click', (e) => {
                        if(modal.classList.contains('open')) {
                            modal.classList.remove('open');
                            newPlayer.stop();
                        }
                    });

                    modal.querySelector('.close-modal').addEventListener('click', (e) => {
                        if(modal.classList.contains('open')) {
                            modal.classList.remove('open');
                            newPlayer.stop();
                        }
                    });
                } else {
                    const newPlayer = new Plyr(player, {
                        debug: false,
                        ratio: '16:9',
                        controls: player.querySelector('.plyr__control')
                    });
    
                    player.querySelector('.plyr__control').addEventListener('click', (e) => {
                        newPlayer.play();
                    });
    
                    newPlayer.on('play', (event) => {
                        console.log(event);
                    });
                }
            });
        }
    }
} 

$(document).ready(function() {
    new Video;
});