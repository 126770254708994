import Flickity from 'flickity';

export default class Carousel {

	constructor(carousel) {

    this.carouselOptions = {
      'mobile' : {
        "wrapAround": false,
        "groupCells" : false,
        "cellAlign" : "left",
        "draggable" : true,
        'freeScroll': true,
      },
      'desktop' : {
        "wrapAround": false,
        "groupCells" : false,
        "cellAlign" : "left",
        "draggable" : true,
        'freeScroll': true,
      }
    };

    let viewType = this.getViewType();

    this.carousel = new Flickity(carousel, this.carouselOptions[viewType]);

    this.viewType = null;
    this.oldViewType = null;

    this.updateCarouselLayout();

    window.addEventListener('resize', () => {
      this.updateCarouselLayout();
    });
  }

  updateCarouselLayout() {
		let newViewType = this.getViewType();

		if (newViewType != this.oldViewType) {
			this.oldViewType = this.viewType;

      this.carousel.options.groupCells = this.carouselOptions[newViewType].groupCells;
      this.carousel.options.cellAlign = this.carouselOptions[newViewType].cellAlign;
			this.carousel.options.draggable = this.carouselOptions[newViewType].draggable; // if the current view is mobile, we want to enable drag on flickity
/*
    if(! this.carousel.$element[0].classList.contains('carousel') ) {
      this.carousel.$element[0].classList.add('carousel');
      this.carousel.$element[0].classList.remove('container');
      carousel.activate();
    }

    */

    if(this.carousel.slides.length < 3) {
      if(this.carousel.$element[0].classList.contains('carousel')) {
        this.carousel.$element[0].querySelector('.flickity-page-dots').classList.add('d-none');
      }
    }
    



			this.carousel.updateDraggable();

		}

		this.viewType = newViewType;
	}

  getViewType() {
    let mediaQueryDetect = document.querySelector('.detect-mobile');
    let display = mediaQueryDetect.currentStyle ? mediaQueryDetect.currentStyle.display : getComputedStyle(mediaQueryDetect, null).display;

    return display == 'none' ? 'mobile' : 'desktop';
  }
}

let carousels = document.querySelectorAll('.carousel');
if(typeof carousels !=="undefined" && carousels !== null) {
  carousels.forEach((carousel) => {
    new Carousel(carousel);
  });
}

/*
document.addEventListener('pageup', function (e) {
  setTimeout(function() {
    let jCarousels = document.querySelectorAll('.jobs-listing');
    if(typeof jCarousels !=="undefined" && jCarousels !== null) {
      jCarousels.forEach((carousel) => {
        new Carousel(carousel);
      });
    }
    alert();
  }, 5000);
});
*/