
	 window.ContactForm = function(contact_form) {
		this.contact_form = contact_form;
		this.form = JSON.parse( this.contact_form.form );

		this.$countryField = $('#input_'+contact_form.form_id+'_'+contact_form.country_id);
		this.$beforeFormHTMLText = $('#field_'+contact_form.form_id+'_'+contact_form.before_form);
		this.$businessUnitFieldContainer = $("#field_"+contact_form.form_id+"_"+contact_form.business_unit_id);
		this.$businessUnitField = $('#input_'+contact_form.form_id+'_'+contact_form.business_unit_id);
		this.$enquiryField = $("#input_"+contact_form.form_id+"_"+contact_form.enquiry_id);
		this.$recipientAddressField = $("#input_"+contact_form.form_id+"_"+contact_form.email_address);
		this.$attachmentCVField = $('#field_'+contact_form.form_id+'_'+contact_form.attachment_cv);
		this.$formContainer = $('.gfmc-row-1-column, #gform_'+contact_form.form_id+' .gform_footer.top_label');
		this.current_enquiry = 0;
		this.current_country = 0;
		this.current_business_unit = 0;

		window.formState = this;
	}

	/**
	 * Loop through all enquiry types.
	 * Populate the Gravity forms 'enquiry type' select box.
	 * Add a data attribute with the index of that enquiry type.
	 */
	ContactForm.prototype.init = function(dynamicalyPopulated) {

		if (typeof this.contact_form == "undefined" || !$('#gform_'+this.contact_form.form_id) ) {
			return;
		}

		this._hideFields([
			this.$businessUnitFieldContainer,
			this.$attachmentCVField,
			this.$beforeFormHTMLText,
			this.$formContainer,
		]);

		this._populateFields(this.form, 'enquiry_type', this.$enquiryField);

		this.bindEvents();


		if (typeof dynamicalyPopulated != "undefined") {
			this.$enquiryField.val(dynamicalyPopulated.enquiry).trigger('change');
			this.$countryField.val(dynamicalyPopulated.country).trigger('change');
			this.$businessUnitField.val(dynamicalyPopulated.businessUnit).trigger('change');
		}

		this.$formContainer.addClass('show');
		this.$formContainer.addClass('gform_hidden');
	}

	ContactForm.prototype.bindEvents = function() {
		this.$enquiryField.on('change', (e) => this.selectEnquiry(e));
		this.$countryField.change((e) => this.selectCountry(e));
		this.$businessUnitField.on('change', (e) => this.selectBusinessUnit(e));
	}

	/**
	 * User Selected Enquiry Type
	 * - Reset Fields
	 * - Populate countries for selected enquiry
	 */
	ContactForm.prototype.selectEnquiry = function(e) {

		this.current_enquiry = this.getSelectedIndex(e);
		// reset fields
		this._emptyFields([
			this.$countryField,
			this.$beforeFormHTMLText,
			this.$businessUnitField
		]);
		this._hideFields([
			this.$businessUnitFieldContainer,
			this.$beforeFormHTMLText,
			this.$attachmentCVField,
			this.$formContainer
		]);


		var country = this._getACFFieldValueCountry( this.current_enquiry );

		this._populateFields(country, 'country_name', this.$countryField);
	}

	/**
	 * User Selected Country
	 * - Set Recipient Email address for country selected.
	 */
	ContactForm.prototype.selectCountry = function(e) {

		this.current_country = this.getSelectedIndex(e);

		this._emptyFields([
			this.$businessUnitField
		]);
		this._hideFields([
			this.$businessUnitFieldContainer,
			this.$beforeFormHTMLText,
			this.$attachmentCVField,
			this.$formContainer,
		])

		if( typeof this.current_country === "undefined") {
			return;
		}

		var currentFormObjectIndex = 0;
		if(this.form[this.current_enquiry] && this.form[this.current_enquiry].hasOwnProperty('country')) {
			currentFormObjectIndex = this.form[this.current_enquiry].country[this.current_country]; // get field data for this country
		}
		if(currentFormObjectIndex.before_form) {
			this.$beforeFormHTMLText.html(currentFormObjectIndex.before_form);

			this._showFields([
				this.$beforeFormHTMLText
			]);
		}

		if(currentFormObjectIndex.hide_form) {
			return;
		}

		this._showFields([
			this.$formContainer,
		]);
		if(this.form[this.current_enquiry].show_form_upload) {
			this._showFields([
				this.$attachmentCVField
			]);
		}

		// set recipient email address
		this.$recipientAddressField.val( currentFormObjectIndex.email_address );

		// Show business unit data
		if( typeof currentFormObjectIndex.additional_options != 'undefined' && currentFormObjectIndex.additional_options ) {
			this._showFields([
				this.$businessUnitFieldContainer,
			]);
			this._populateFields(currentFormObjectIndex.additional_options, 'option_name', this.$businessUnitField);
		}
	}
	
	/**
	 * User Selected Business Unit
	 * - Set Recipient Email address for country selected.
	 */
	ContactForm.prototype.selectBusinessUnit = function(e) {
		this.current_business_unit = this.getSelectedIndex(e);

if(typeof this.form[this.current_enquiry].country)
		if( typeof this.form[this.current_enquiry].country[this.current_country] === "undefined") {
			return;
		}

		var currentFormObjectIndex = this.form[this.current_enquiry].country[this.current_country].additional_options[this.current_business_unit];

		this._hideFields([this.$beforeFormHTMLText]);

		if( typeof currentFormObjectIndex === "undefined") {
			return;
		}

		if(currentFormObjectIndex.before_form) {
			this._showFields([this.$beforeFormHTMLText]);
			this.$beforeFormHTMLText.html(currentFormObjectIndex.before_form);
		}

		if(currentFormObjectIndex.email_address) {
			this.$recipientAddressField.val( currentFormObjectIndex.email_address );
		}
	}

	ContactForm.prototype._hideFields = function(fields) {
		$.each( fields, function(key, value) {
			$(value).addClass('gform_hidden');
		});
	},

	ContactForm.prototype._emptyFields = function(fields) {
		$.each( fields, function(key, value) {
			$(value).empty();
		});
	}

	ContactForm.prototype._showFields = function(fields) {
		$.each( fields, function(key, value) {
			$(value).removeClass('gform_hidden');
		});
	}


	ContactForm.prototype._getACFFieldValueCountry = function(current_index) {
		if(typeof this.form[current_index] !=="undefined") {
			if( typeof this.current_enquiry === "undefined" || !this.form[current_index].hasOwnProperty('country') ) {
				return 0;
			}
			return this.form[current_index].country;
		}
	}
	ContactForm.prototype._populateFields = function(list, prop, $selector) {

		var option = new Option('Please Select');
		$selector.empty();
		$selector.append(option);

		$.each( list, function(key, value) {
			if( value.hasOwnProperty(prop) ) {
				var option = new Option(value[prop]);
				$selector.append(option);
				$(option).data('index', key);
				$(option).val(value[prop]);
			}
		});
	}

	ContactForm.prototype.getSelectedIndex = function(e) {
		window.index = $(e.currentTarget).prop('selectedIndex') -1;
		return $(e.currentTarget).prop('selectedIndex') > 0 ? $(e.currentTarget).prop('selectedIndex')  -1 : 0;
	}

jQuery(document).ready(function($) {
		if( typeof window.contact_form == 'object' ) {
			var contact = new ContactForm(window.contact_form);
			contact.init();
		}
}(jQuery));



let enqState = '';
let countryState = '';
let isSubmitted = false;
jQuery(document).on('submit', '#gform_3', function(e) {
	enqState = formState.$enquiryField.val();
	countryState =	formState.$countryField.val();
	isSubmitted = true;
})

jQuery(document).on('gform_post_render', function(event, form_id, current_page){
	if(isSubmitted) {
		setTimeout(function() {
			formState.$enquiryField.val(enqState).change();
			formState.$countryField.val(countryState).change();
		}, 500)
	}
});
