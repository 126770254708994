// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebook, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import jump from 'jump.js';
import Flickity from 'flickity';
import Menu from './layouts/popover-menu.js';
import Carousel from './components/carousel.js';
import Accordion from 'accordion-js';
import Plyr from 'plyr';

//require('./layouts/popover-menu.js');
require('./components/hero.js');
require('./components/carousel.js');
require('./forms/form.js');
require('./components/video.js');
require('./components/ajax-load-posts.js');
require('./components/ajax-load-insights.js');
require('./components/map.js');
require('./components/compliments-complaints.js');
require('./components/home-section-animations.js');

/**
 * Add Font Awesome
 */
library.add(faFacebook, faTwitter, faLinkedin, faYoutube);
dom.watch();

/**
 * Scroll to anchors
 */
if(typeof document.querySelectorAll('.accordion-container') && document.querySelectorAll('.accordion-container') !== null) {
  document.querySelectorAll('.accordion-container').forEach(function(item) {
    new Accordion(item);
  });
}

let links = document.querySelectorAll('.has-hash');

if(typeof links !=="undefined" && links !==null) {
  links.forEach((link) => {
    if (link.hash) {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        jump(link.hash, {
          duration: 500,
          offset: 0,
          callback: undefined,
          a11y: false
        })
      });
    }
  })
}


const mobileNav = document.querySelector('.js-mobile-opt-nav');

if( mobileNav !== null && typeof mobileNav !=="undefined" ) {
  mobileNav.addEventListener('change', (e) => {
    window.location = e.target.value;
  });
}


document.querySelectorAll('.primary-menu__link--has-child').forEach((item) => {
  item.addEventListener('click', (event) => {
    event.preventDefault();
    item.classList.toggle('active');
  });
});

document.querySelectorAll('.primary-menu__back-button').forEach((item) => {
  item.addEventListener('click', (event) => {
    event.preventDefault();
    document.querySelector('.primary-menu__link--has-child.active').classList.remove('active');
  });
});

document.querySelector('.js-hamburger').addEventListener('click', (event) => {
  event.preventDefault();
  event.currentTarget.classList.toggle('is-active');
  document.querySelector('.menu').classList.toggle('active');
});


const player = new Plyr('.plyr-hero', {
  autoplay: true,
  muted: true,
  volume: 0,
  controls: false,
});

window.player  = player;

setTimeout(() => {
  player.play();
}, 2000);




const cards = document.querySelectorAll('.text-image__card--right.text-image__card--keep-proportions');

  cards.forEach(card => {
    window.textContainer = card.querySelector('.text-image__card-text');
    window.imageContainer = card.querySelector('.text-image__card-image-container');

    // Get the heights of the text and image
    window.textHeight = textContainer.scrollHeight;
    window.imageHeight = imageContainer.clientHeight;

    // If text height is greater than image height, move the excess
    if (textHeight > imageHeight) {
      // Get the difference in height
      window.excessHeight = textHeight - imageHeight;

        // Find how much text needs to be moved
        window.textNodes = Array.from(textContainer.children);
        let movedText = '';

        window.excessTextElement = document.createElement('div');

        while (textContainer.scrollHeight > imageHeight+200 && textNodes.length > 0) {
          // Move nodes one by one until the height matches
          const nodeToMove = textNodes.pop();
          movedText = nodeToMove.outerHTML + movedText;
          nodeToMove.remove();

          excessTextElement.innerHTML = movedText;
          imageContainer.appendChild(excessTextElement);

          window.imageHeight = imageContainer.clientHeight;

          console.log(textContainer.scrollHeight, imageHeight);
        }
      }
  });
