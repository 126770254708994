import '../autoload/jquery.datetimepicker.js';

(function($) {
  var jQuery = $;

  if(jQuery("#complaints_form").length > 0){

    jQuery.get("https://ipinfo.io", function (response) {
        // alert(response.ip);
        document.getElementById("IPAddress").value = response.ip;
    }, "jsonp");
    
    
    var $ = jQuery;
    $.fn.serializeObject=function(){"use strict";var a={},b=function(b,c){var d=a[c.name];"undefined"!=typeof d&&d!==null?$.isArray(d)?d.push(c.value):a[c.name]=[d,c.value]:a[c.name]=c.value};return $.each(this.serializeArray(),b),a};

    
    jQuery('#DateofEvent').datetimepicker();
    
    
    var guid = (function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    function dateToday() {
      var today = new Date();
      var str = today.getMonth()+1 + '' + today.getFullYear() + '' + today.getHours() + '' + today.getMinutes()
      return str;
    }
    return function () {
      return dateToday();
    };
    })();
    
    if(jQuery("#complaints_form").length > 0){
    jQuery("#complaints_form #ReferenceNo").val(guid());
    }

    jQuery('#complaints_form').on('submit', function(e) {
    e.preventDefault();
    
    var formData = jQuery(this).serializeObject();
    formData.ReferenceNo = formData.ReferenceNo * 1;
    
    
    window.formData = formData;
    jQuery.ajax({
        url: 'https://prod-17.australiasoutheast.logic.azure.com:443/workflows/df8c20cec1454c13a66a0b5387c58a38/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2933BSn9dZw-STLHV66PS81lK-nxA_OZoAVVIowLy6k',
        type: 'post',
        dataType: 'json',
        contentType: 'application/json', 
        success: function (data) {
          if(typeof data.error !=="undefined" && data.error == null) {
              $('#confirmationBox').html(data.error.msg);
              $('#confirmationBox').addClass('alert-danger');
          } else {
            //  $('#target').html(data.msg); 
          }
        },
        complete: function (xhr) {
              jQuery("#complaints_form")[0].reset();
              jQuery("#SubmitButton").html('Send <i class="fa fa-check">');
              jQuery("#complaints-form-container").hide();
              jQuery("#confirmationBox").show();

              window.location.hash='confirmationBox';
        }, 
        beforeSend: function () {
          jQuery("#SubmitButton").html('Sending...');
          jQuery("#refNo").text(jQuery("#complaints_form #ReferenceNo").val());
        },
        data: JSON.stringify(formData)
      });
    });
  }

})(jQuery);