class AjaxLoadPosts {
  constructor() {
    document.body.addEventListener("click", (e) => {
      // Toggle the submenu on main nav items click
      if( e.target.dataset.hasOwnProperty('loadPosts') )  {
        e.preventDefault();
        this.init(e.target);
      }
    });

  }

  async init(link) {
    window.history.pushState('', '', link.href);
    this.link = link;
    this.loadMoreWasClicked = link.dataset.hasOwnProperty('append');

    this.query = window.location.pathname + window.location.search; // update query with new url

    this.response = await this.getPosts();
    let posts = await this.response.text();
    await this.paginate();

    if( this.loadMoreWasClicked ) {
      let appendPosts = posts;
      document.querySelector('.card-layout__cards').innerHTML += appendPosts;
    } else { // clear existing html
      document.querySelector('.card-layout__cards').innerHTML = posts;
    }
  }

  async getPosts() {
    return await fetch(admin_url.ajax_url+'?action=get_post_ajax&query='+this.query, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  async paginate() {

    let headers = await this.response.headers;
    let currentPage = headers.get('x-wp-current')*1;
    let totalPages = headers.get('x-wp-total')*1;
    let postsEnd = headers.get('x-wp-posts-end');

    if(document.querySelector('[data-append]')) {
      document.querySelector('[data-append]').remove(); // remove loadmore
    }

    if( currentPage != totalPages && postsEnd == 'false' ) { // update loadmore link and re-append
      let newLoadMoreBtn = this.createlink();
      this.link = this.updatePagination(newLoadMoreBtn, currentPage+1);

      document.querySelector('[data-ajax-load-more]').appendChild(this.link);
    }
  }

  createlink() {
    let a = document.createElement('a');
    a.href = this.link.href;
    a.dataset.loadPosts = 1;
    a.dataset.append = 1;
    a.classList.add('button');
    a.innerText = 'Load More';

    return a;
  }

  updatePagination(link, page = 1) {
    let href = new URL(link.href);

    if(href.searchParams.get('paged')) {
      href.searchParams.set('paged', page);
    } else {
      href.searchParams.append('paged', page);
    }

    link.href = href;

    return link;
  }
}

let loadPostsLinks = document.querySelector('[data-load-posts]');

if(typeof loadPostsLinks && loadPostsLinks !== null) {
    new AjaxLoadPosts();
}