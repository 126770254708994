
class AjaxLoadInsights {
  constructor() {
    document.body.addEventListener("click", (e) => {
      // Toggle the submenu on main nav items click
      if( e.target.dataset.hasOwnProperty('loadInsights') )  {
        e.preventDefault();
        this.init(e.target);
      }
    });
  }

  async init(link) {
    // window.history.pushState('', '', link.href);
    this.link = link;
    this.loadMoreWasClicked = link.dataset.hasOwnProperty('append');

    this.query = link.pathname + link.search; // update query with new url

    document.querySelector('.news-and-insights__list').classList.add('hidden');
    const flkty = Flickity.data('.news-and-insights__list.carousel');
    flkty.destroy();

    this.response = await this.getPosts();
    let posts = await this.response.text();

    document.querySelector('.news-and-insights__list').innerHTML = posts;

    var carousel = new Carousel(document.querySelector('.news-and-insights__list.carousel'));
    document.querySelector('.news-and-insights__list').classList.remove('hidden');
  }

  async getPosts() {
    return await fetch(admin_url.ajax_url+'?action=get_post_ajax&query='+this.query+'&template=card-news-and-insights', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
}

let loadInsightsLinks = document.querySelector('[data-load-insights]');
if(typeof loadInsightsLinks && loadInsightsLinks !== null) {
    new AjaxLoadInsights();
}
