class Menu {
  constructor() {
    // set height of child to be even with parent
    this.initEvents();
    this.resizeTimer = 0;
  }

  initEvents() {

    document.body.addEventListener("click", (e) => {

      // Toggle the menu
      if( e.target.classList.contains('js-search') ) {
        e.preventDefault();
        document.body.classList.toggle('search-active');

        document.querySelector('.search-popover__search-input').focus();
      }

      // Toggle the menu
      if( e.target.classList.contains('js-chapters') ) {
        e.preventDefault();
        document.body.classList.toggle('chapters-menu-active');
        document.body.classList.remove('menu-active');
        e.target.classList.toggle('hamburger--collapse');
        e.target.classList.toggle('is-active');

        document.querySelector('.js-hamburger').classList.remove('is-actve');
      }
    });


    document.onkeydown = function(evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
          isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        document.body.classList.remove('search-active');
      }
    };



    const menu = document.querySelector('.header__menu');
    const trigger = document.querySelector('.hero');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          menu.classList.add('show');

        } else {
          menu.classList.remove('show');
        }
      });
    }, { threshold: 0 });

    observer.observe(trigger);

  }

}

new Menu();
