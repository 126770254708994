import enterView from 'enter-view';

class HomeSectionAnimations {
    constructor() {
        this.prepareAnimatedSections();
        this.initSectionDetection();
    }

    initSectionDetection() {
        enterView({
            selector: '.arrow-cta',
            enter: function(el) {
                console.log(el);
                el.classList.add('animated');
            },
            offset: 0.5
        });
    }

    prepareAnimatedSections() {
        const sections = document.querySelectorAll('.arrow-cta, .news-and-insights');

        sections.forEach((section) => {
            section.classList.add('to-be-animated');
        });
    }
}

$(document).ready(function() {
    if(document.body.classList.contains('home')) {
        new HomeSectionAnimations;
    }
});